exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bounty-tsx": () => import("./../../../src/pages/bounty.tsx" /* webpackChunkName: "component---src-pages-bounty-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-sales-tsx": () => import("./../../../src/pages/contact-sales.tsx" /* webpackChunkName: "component---src-pages-contact-sales-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-create-your-role-tsx": () => import("./../../../src/pages/create-your-role.tsx" /* webpackChunkName: "component---src-pages-create-your-role-tsx" */),
  "component---src-pages-data-delete-form-tsx": () => import("./../../../src/pages/data-delete-form.tsx" /* webpackChunkName: "component---src-pages-data-delete-form-tsx" */),
  "component---src-pages-documents-tsx": () => import("./../../../src/pages/documents.tsx" /* webpackChunkName: "component---src-pages-documents-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-feature-request-tsx": () => import("./../../../src/pages/feature-request.tsx" /* webpackChunkName: "component---src-pages-feature-request-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-reports-2022-q-2-tsx": () => import("./../../../src/pages/reports/2022/q2.tsx" /* webpackChunkName: "component---src-pages-reports-2022-q-2-tsx" */),
  "component---src-pages-unsubscribe-tsx": () => import("./../../../src/pages/unsubscribe.tsx" /* webpackChunkName: "component---src-pages-unsubscribe-tsx" */),
  "component---src-pages-user-guide-tsx": () => import("./../../../src/pages/user-guide.tsx" /* webpackChunkName: "component---src-pages-user-guide-tsx" */),
  "component---src-templates-document-document-tsx": () => import("./../../../src/templates/Document/Document.tsx" /* webpackChunkName: "component---src-templates-document-document-tsx" */),
  "component---src-templates-faq-additional-article-faq-additional-article-tsx": () => import("./../../../src/templates/FaqAdditionalArticle/FaqAdditionalArticle.tsx" /* webpackChunkName: "component---src-templates-faq-additional-article-faq-additional-article-tsx" */),
  "component---src-templates-faq-article-faq-article-tsx": () => import("./../../../src/templates/FaqArticle/FaqArticle.tsx" /* webpackChunkName: "component---src-templates-faq-article-faq-article-tsx" */),
  "component---src-templates-investor-report-investor-report-tsx": () => import("./../../../src/templates/InvestorReport/InvestorReport.tsx" /* webpackChunkName: "component---src-templates-investor-report-investor-report-tsx" */),
  "component---src-templates-product-product-tsx": () => import("./../../../src/templates/Product/Product.tsx" /* webpackChunkName: "component---src-templates-product-product-tsx" */),
  "component---src-templates-protected-document-protected-document-tsx": () => import("./../../../src/templates/ProtectedDocument/ProtectedDocument.tsx" /* webpackChunkName: "component---src-templates-protected-document-protected-document-tsx" */),
  "component---src-templates-user-guide-article-user-guide-article-tsx": () => import("./../../../src/templates/UserGuideArticle/UserGuideArticle.tsx" /* webpackChunkName: "component---src-templates-user-guide-article-user-guide-article-tsx" */),
  "component---src-templates-vacancy-vacancy-tsx": () => import("./../../../src/templates/Vacancy/Vacancy.tsx" /* webpackChunkName: "component---src-templates-vacancy-vacancy-tsx" */)
}

